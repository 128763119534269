import { default as indexha3Jcp60J6Meta } from "/var/www/nuxt-v3/prod/releases/12/pages/index.vue?macro=true";
import { default as simulation_45pinelSzLODG3X4vMeta } from "/var/www/nuxt-v3/prod/releases/12/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptz9DgMZ27Hn6Meta } from "/var/www/nuxt-v3/prod/releases/12/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexha3Jcp60J6Meta?.name ?? "index",
    path: indexha3Jcp60J6Meta?.path ?? "/",
    meta: indexha3Jcp60J6Meta || {},
    alias: indexha3Jcp60J6Meta?.alias || [],
    redirect: indexha3Jcp60J6Meta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/12/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pinelSzLODG3X4vMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pinelSzLODG3X4vMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pinelSzLODG3X4vMeta || {},
    alias: simulation_45pinelSzLODG3X4vMeta?.alias || [],
    redirect: simulation_45pinelSzLODG3X4vMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/12/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptz9DgMZ27Hn6Meta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptz9DgMZ27Hn6Meta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptz9DgMZ27Hn6Meta || {},
    alias: simulation_45ptz9DgMZ27Hn6Meta?.alias || [],
    redirect: simulation_45ptz9DgMZ27Hn6Meta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/12/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]